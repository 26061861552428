import { mapState } from "vuex";
import bouncer from "@/helpers/bouncer";
import catalogService from "@/modules/core/service/catalog.service";
import {
  chargeAccess,
  componentsNames,
  mapGettersType,
  notificationMessages,
  typeNotification,
} from "@/helpers/consts";

export default {
  mixins: [bouncer],
  name: componentsNames.CHARGE,
  data() {
    return {
      dialogTableHeaderAdministrative: ["Listar", "Actualizar", "Eliminar", "Crear"],
      access: chargeAccess,
      formDialogPermissions: false,
      abilitiesCheck: [],
      permissions_crud: [],
      permissions_ncrud: [],
      path: "positions",
      confirmDeleteModal: false,
      processing: false,
      submitted: false,
      formDialog: false,
      tableFilters: {},
      items: null,
      currentItem: null,
      layout: "list",
      typeRegister: [
        { name: "Filial", code: "1" },
        { name: "Pais", code: "2" },
        { name: "Todos", code: "3" },
      ],
      selectedType: null,
    };
  },
  carService: null,
  eventService: null,
  nodeService: null,
  created() {},
  mounted() {
    if (this.canAccess(chargeAccess.CHARGE_READ)) {
      this.getData();
    }
  },
  methods: {
    notification(severity, summary, detail) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        life: this.$utils.toastLifeTime(),
      });
    },
    assignmentAbilities() {
      this.processing = true;
      catalogService
        .assignmentAbilities(this.itemSelect.id, {
          abilities: this.abilitiesCheck,
        })
        .then(() => {
          this.formDialogPermissions = false;
          const detail = "Asignación de habilidades guardada correctamente";
          this.notification(
            typeNotification.INFO,
            notificationMessages.ATTENTION,
            detail
          );
        })
        .catch(() => {
          this.notification(
            typeNotification.ERROR,
            notificationMessages.error,
            notificationMessages.THERE_WAS_AN_ERROR
          );
        })
        .finally(() => {
          this.processing = false;
        });
    },
    abilities(id) {
      this.processing = true;
      catalogService
        .abilities(id)
        .then((response) => {
          this.abilitiesCheck = response.data.check;
          this.permissions_crud = response.data.permissions_crud;
          this.permissions_ncrud = response.data.permissions_ncrud;
          this.formDialogPermissions = true;
        })
        .catch(() => {
          this.notification(
            typeNotification.ERROR,
            notificationMessages.error,
            notificationMessages.THERE_WAS_AN_ERROR
          );
        })
        .finally(() => {
          this.processing = false;
        });
    },
    saveItem() {
      this.submitted = true;
      if (!this.currentItem.description || !this.currentItem.order) {
        return;
      }
      this.processing = true;
      this.formDialog = false;
      this.currentItem.filter_for_commission = this.selectedType.name;
      catalogService
        .save(
          this.path,
          this.currentItem,
          this.currentItem ? this.currentItem.id : undefined
        )
        .then(() => {
          this.getData();
          this.notification(
            typeNotification.SUCCESS,
            notificationMessages.record_saved,
            notificationMessages.SAVED_SUCCESSFULLY
          );
        })
        .catch((error) => {
          const message = error.response.data;
          let errorDetail = "";
          Object.keys(message).forEach((key) => {
            errorDetail +=
              errorDetail + message[key] && [...message[key]].shift()
                ? [...message[key]].shift() + "\n "
                : "";
          });
          this.notification(
            typeNotification.ERROR,
            notificationMessages.error,
            errorDetail
          );
        })
        .finally(() => {
          this.submitted = false;
          this.processing = false;
        });
    },
    hideDialog() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = false;
    },
    openNew() {
      this.submitted = false;
      this.currentItem = {};
      this.formDialog = true;
      this.selectedType = null;
    },
    permissionsLoad(permission) {
      this.itemSelect = permission;
      this.abilities(permission.id);
    },
    getData() {
      this.processing = true;
      catalogService
        .get(this.path)
        .then((response) => {
          this.items = response.data;
        })
        .catch(() => {
          this.notification(
            typeNotification.ERROR,
            notificationMessages.error,
            notificationMessages.THERE_WAS_AN_ERROR
          );
        })
        .finally(() => {
          this.processing = false;
        });
    },
    confirmDelete(confirmed) {
      this.currentItem = confirmed;
      this.confirmDeleteModal = true;
    },
    edit(charge) {
      this.currentItem = charge;
      this.submitted = false;
      this.formDialog = true;
      const filialTypeSearch = "Filial";
      const countryTypeSearch = "Pais";
      const allTypeSearch = "Todos";
      if (this.currentItem.filter_for_commission === filialTypeSearch) {
        [this.selectedType] = this.typeRegister;
      } else if (this.currentItem.filter_for_commission === countryTypeSearch) {
        this.selectedType = this.typeRegister[1];
      } else if (this.currentItem.filter_for_commission === allTypeSearch) {
        this.selectedType = this.typeRegister[2];
      }
    },
    deleteRow() {
      this.processing = true;
      this.confirmDeleteModal = false;
      catalogService
        .delete(this.path, this.currentItem.id)
        .then(() => {
          this.currentItem = {};

          this.notification(
            typeNotification.SUCCESS,
            notificationMessages.record_deleted,
            notificationMessages.DELETED_SUCCESSFULLY
          );
          this.getData();
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
  computed: {
    ...mapState(mapGettersType.AUTH, [mapGettersType.IS_ADMIN]),
  },
};
