<template>
  <div v-if="canAccess(access.CHARGE_READ)" class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <DataTable
          :value="items"
          :filters="tableFilters"
          class="p-datatable-responsive-demo p-datatable-sm"
          v-model:selection="currentItem"
          selectionMode="single"
          dataKey="id"
          :paginator="true"
          paginatorPosition="bottom"
          :rows="10"
        >
          <template #header>
            <div class="card p-grid p-dir-row p-col-12 p-justify-between">
              <div class="p-col-12">
                <ProgressBar mode="indeterminate" v-if="processing" />
              </div>
              <h5 class="p-grid p-dir-row p-col-12 p-sm-6">
                <span class="p-col-fixed">Gestionar Cargos</span>
              </h5>
              <div
                class="p-grid p-dir-row p-justify-start p-jc-sm-end p-col-12 p-sm-6"
              >
                <div class="p-col-fixed">
                  <span class="p-float-label">
                    <InputText id="enroll" v-model="tableFilters['global']" />
                    <label for="enroll">Buscar</label>
                  </span>
                </div>
                <div class="p-col-fixed">
                  <Button
                    v-if="canAccess('cargos_create')"
                    label="Agregar"
                    icon="pi pi-plus"
                    class="p-button-success"
                    @click="openNew"
                  />
                </div>
              </div>
            </div>
          </template>
          <Column field="id" header="Código" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              <span class="p-column-value">
                {{ slotProps.data.id }}
              </span>
            </template>
          </Column>
          <Column field="order" header="Orden" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Orden</span>
              <span class="p-column-value">
                {{ slotProps.data.order }}
              </span>
            </template>
          </Column>
          <Column field="description" header="Nombre" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              <span class="p-column-value">
                {{ slotProps.data.description }}
              </span>
            </template>
          </Column>
          <Column
            field="filter_for_commission"
            header="Escala"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Escala</span>
              <span class="p-column-value">
                {{ slotProps.data.filter_for_commission }}
              </span>
            </template>
          </Column>
          <Column header="Opciones">
            <template #body="slotProps">
              <span class="p-column-title">Opciones</span>
              <Button
                v-tooltip="'Permisos del sistema'"
                v-if="
                  canAccess(access.CHARGE_UPDATE) &&
                  canAccess(access.CHARGE_CREATE)
                "
                icon="pi pi-cog"
                class="p-button-rounded p-button-info p-mr-2"
                @click="permissionsLoad(slotProps.data)"
              />
              <Button
                v-tooltip="'Editar'"
                v-if="canAccess(access.CHARGE_UPDATE)"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="edit(slotProps.data)"
              />
              <Button
                v-tooltip="'Eliminar'"
                v-if="canAccess(access.CHARGE_DELETE)"
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmDelete(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-if="
            canAccess(access.CHARGE_UPDATE) || canAccess(access.CHARGE_CREATE)
          "
          v-model:visible="formDialog"
          header="Cargo"
          :modal="true"
          class="p-col-12 p-sm-10 p-md-8 p-lg-6 p-xl-3"
        >
          <div class="p-fluid p-grid p-pt-5 size-dialog">
            <div class="p-field p-fluid p-col-12" v-if="currentItem">
              <span class="p-float-label">
                <InputText
                  id="description"
                  v-model.trim="currentItem.description"
                  required="true"
                  autofocus
                  :class="{
                    'p-invalid': submitted && !currentItem.description,
                  }"
                />
                <label for="description">Nombre</label>
              </span>
              <small
                class="p-invalid"
                v-if="submitted && !currentItem.description"
              >
                La descripción es requerida
              </small>
            </div>
            <div class="p-field p-fluid p-col-12" v-if="currentItem">
              <span class="p-float-label">
                <InputText
                  id="order"
                  v-model.trim="currentItem.order"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !currentItem.order }"
                  type="number"
                />
                <label for="order">Orden</label>
              </span>
              <small class="p-invalid" v-if="submitted && !currentItem.order">
                El órden es requerido
              </small>
            </div>
            <div class="p-field p-fluid p-col-12" v-if="currentItem">
              <span class="p-float-label">
                <Dropdown
                  id="filter_for_commission"
                  v-model="selectedType"
                  :options="typeRegister"
                  optionLabel="name"
                />
                <label for="filter_for_commission">Tipo</label>
              </span>
            </div>
          </div>
          <template #footer>
            <div class="p-field p-fluid p-col-12">
              <Button
                label="Cancelar"
                icon="pi pi-times"
                class="p-button-text"
                @click="hideDialog"
              />
              <Button
                label="Guardar"
                icon="pi pi-check"
                class="p-button-text"
                @click="saveItem"
              />
            </div>
          </template>
        </Dialog>
        <Dialog
          v-if="canAccess('cargos_update') || canAccess('cargos_create')"
          v-model:visible="formDialogPermissions"
          header="Permisos Asignados"
          :modal="true"
          class="p-fluid p-col-12 p-md-11 p-lg-10 p-xl-8"
        >
          <TabView :activeIndex="0">
            <TabPanel header="Módulos Adminsitrativos">
              <div class="size-tabpanel">
                <table
                  v-if="permissions_crud"
                  class="table-module-administrative"
                >
                  <thead>
                    <tr>
                      <th>
                        <span class="dialogTable-head">MÓDULO</span>
                      </th>
                      <th>
                        <span class="dialogTable-head">LISTAR</span>
                      </th>
                      <th>
                        <span class="dialogTable-head">ACTUALIZAR</span>
                      </th>
                      <th>
                        <span class="dialogTable-head">ELIMINAR</span>
                      </th>
                      <th>
                        <span class="dialogTable-head">CREAR</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        itemPermissions, keyPermissions
                      ) in permissions_crud"
                      :key="keyPermissions"
                    >
                      <td class="dialogTable-title">
                        {{ keyPermissions.toUpperCase() }}
                      </td>
                      <td
                        v-for="(ability, keyAbility) in itemPermissions"
                        :key="keyAbility"
                      >
                        <small class="dialogTable-title-item">
                          {{ dialogTableHeaderAdministrative[keyAbility] }}
                        </small>
                        <Checkbox
                          v-tooltip.bottom="ability.title"
                          :id="'ability_' + ability.id"
                          :name="'ability_' + ability.id"
                          :value="ability.id"
                          v-model="abilitiesCheck"
                          class="dialogTable-value"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </TabPanel>
            <TabPanel header="Módulos Sistema">
              <div class="size-tabpanel">
                <div
                  v-for="(itemPermissions, keyPermissions) in permissions_ncrud"
                  :key="keyPermissions"
                >
                  <table class="table-module-system">
                    <thead>
                      <tr class="p-selectable-row">
                        <th>
                          <span class="table-module-system-head">MÓDULO</span>
                        </th>
                        <th
                          v-for="(ability, keyAbility) in itemPermissions"
                          :key="keyAbility"
                        >
                          <span class="table-module-system-head">
                            {{ ability.title.toUpperCase() }}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>{{ keyPermissions.toUpperCase() }}</strong>
                        </td>
                        <td
                          v-for="(ability, keyAbility) in itemPermissions"
                          :key="keyAbility"
                        >
                          <span class="table-module-system-title">
                            {{ ability.title.toUpperCase() }}
                          </span>
                          <Checkbox
                            v-tooltip.bottom="ability.title"
                            :id="'ability_' + ability.id"
                            :name="'ability_' + ability.id"
                            :value="ability.id"
                            v-model="abilitiesCheck"
                            class="table-module-system-value"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
          </TabView>
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="
                formDialogPermissions = false;
                abilitiesCheck = [];
              "
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="assignmentAbilities"
            />
          </template>
        </Dialog>
        <Dialog
          v-if="canAccess('cargos_delete')"
          v-model:visible="confirmDeleteModal"
          :style="{ width: '28.125rem' }"
          header="Confirm"
          :modal="true"
        >
          <div class="p-fluid confirmation-content p-pt-5">
            <i class="pi pi-exclamation-triangle size-icon-delete" />
            <span v-if="currentItem">Seguro de eliminar registro?</span>
          </div>
          <template #footer>
            <div class="p-field p-fluid p-col-12">
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="confirmDeleteModal = false"
              />
              <Button
                label="Si"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteRow"
              />
            </div>
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>¡ATENCIÓN!</b></h5>
    <div class="card-body">
      <p class="card-title">
        El acceso a esta opción no se encuentra autorizado
      </p>
      <Button
        label="Regresar"
        class="p-button-danger p-mr-2"
        @click="$router.push('/admin')"
      />
    </div>
  </div>
</template>
<script src="./Charge.js"></script>
<style src="./Charge.scss" lang="scss" scoped></style>
